@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .blue-btn {
        @apply inline-flex items-center px-4 py-2 border border-gray-300 bg-slate-700 text-sm font-medium text-white hover:bg-slate-600;
    }
}

.marker-bg-hover:hover {
    background-color: #333333;
}